export default class WpetCrossSelling {

    constructor()
    {
        WpetCrossSelling.initializeCrossSelling();
    }

    static initializeCrossSelling()
    {
        let $tabOffers = $( '#nav-tabs .nav-link.active' );

        let wpet_cross = {

            url: Theme.serverURL + '/wp-json/irisit/post_type/',
            map: null,
            mapObject: null,
            query: {},
            markers: [],
            bounds: null,
            infoWindow: null,
            current: null,

            init_map: function () {
                wpet_cross.mapObject = new window.wpet_map_object.mapAdapter.Map( 'wpet-map-cross' );
                wpet_cross.map       = wpet_cross.mapObject.map;
                wpet_cross.map.scrollWheelZoom.disable();
            },

            callApi: function ( tabId ) {

                let post_types = $( '#' + tabId ).attr( 'data-wpet-cross-type' );

                if ( post_types !== '' && post_types !== undefined ) {

                    let crossIds = [];
                    let results  = [];
                    let urlRest  = wpet_cross.url + post_types;

                    //get all cross ids for current tab
                    $( '#' + tabId + ' [data-wpet-cross-item]' ).each( function ( index, value ) {
                        crossIds.push( $( this ).attr( 'data-wpet-cross-item' ) );
                    } );

                    //check if we need to ask rest route
                    if ( typeof wpet_cross.query[ post_types ] !== "undefined" ) {

                        wpet_cross.buidMarkers( wpet_cross.query[ post_types ] );

                    } else {

                        $.ajax( {
                            url: urlRest,
                            type: 'POST',
                            data: {
                                include: crossIds,
                                posts_per_page: crossIds.length,
                                mapmode: true
                            },
                            success: function ( items ) {

                                //tread items
                                if ( typeof items.allPosts !== "undefined" ) {
                                    results = items.allPosts;
                                }

                                wpet_cross.query[ post_types ] = results;
                                wpet_cross.buidMarkers( results );

                            }
                        } );
                    }
                }
            },

            init_marker: function () {

                //get tab offers
                let pillsId = [];
                $tabOffers.each( function () {
                    pillsId.push( $( this ).attr( 'aria-controls' ) );
                } );

                $( '.tab-pane' ).hide();
                if ( $( '.wpetBottombar' ).length == 0 ) {
                    $( '#' + pillsId[ 0 ] ).show();
                }

                //call rest route
                for ( let key in pillsId ) {
                    wpet_cross.callApi( pillsId[ key ] );
                }
            },

            /**
             * Build markers
             * @param items
             */
            buidMarkers: function ( items ) {

                //reset markers
                if ( wpet_cross.markers.length > 0 ) {

                    for ( let i in wpet_cross.markers ) {
                        wpet_cross.markers[ i ].reset( wpet_cross.map );
                    }
                    wpet_cross.markers = [];

                }

                wpet_cross.bounds = new window.wpet_map_object.mapAdapter.Bounds();

                // add markers on map
                for ( let i = 0; i < items.length; i++ ) {

                    if ( items[ i ].wpet_fields.latitude != '' && items[ i ].wpet_fields.latitude != undefined && items[ i ].wpet_fields.latitude != null
                        && items[ i ].wpet_fields.longitude != '' && items[ i ].wpet_fields.longitude != undefined && items[ i ].wpet_fields.longitude != null
                    ) {
                        // Set latLng object
                        let latLng = new window.wpet_map_object.mapAdapter.LatLng( items[ i ].wpet_fields.latitude, items[ i ].wpet_fields.longitude );

                        let marker_data = wpetMaps.options.marker;

                        let markerOpt = {
                            title: items[ i ].post_title,
                            visible: true,
                            id: items[ i ].ID,
                            is_reference: false,
                            oldicon: marker_data,
                            iconSize: [
                                30,
                                30
                            ],

                        };

                        let marker = new window.wpet_map_object.mapAdapter.Marker( latLng, wpet_cross.map, marker_data, markerOpt );

                        if ( typeof items[ i ].wpet_fields.info_window != 'undefined' && items[ i ].wpet_fields.info_window != '' ) {
                            marker.setInfoWindow( items[ i ].wpet_fields.info_window, undefined, wpet_cross.map );
                        } else {
                            marker.setInfoWindow(
                                '<div class="infoBox"><p><strong><a target="blank" href="' + items[ i ].guid + '">' + items[ i ].post_title + '</strong></p></div>',
                                undefined, wpet_cross.map );
                        }

                        wpet_cross.markers.push( marker );

                        wpet_cross.bounds.extend( latLng );

                    }
                }

                let $data_wpet_cross_item = $( '[data-wpet-cross-item]' );

                $( document ).bind( 'wpet_map_popup_open', function ( e, currentMarker ) {

                    $data_wpet_cross_item.removeClass( 'hover' );
                    $.each( wpet_cross.markers, function ( index, value ) {
                        value.setIcon( value.marker.oldicon );
                    } );

                    $( '[data-wpet-cross-item="' + currentMarker.id + '"]' ).addClass( 'hover' );
                    $.each( wpet_cross.markers, function ( index, value ) {
                        if ( value.marker.id == currentMarker.id ) {
                            value.setIcon( wpetMaps.options.markerhover );
                        }
                    } );

                } );

                $( document ).bind( 'wpet_map_popup_close', function ( e ) {
                    $data_wpet_cross_item.removeClass( 'hover' );
                    $.each( wpet_cross.markers, function ( index, value ) {
                        value.setIcon( value.marker.oldicon );
                    } );
                } );

                //click cross item
                $data_wpet_cross_item.bind( 'click', function () {

                    let indexHover = $( this ).attr( 'data-wpet-cross-item' );
                    $.each( wpet_cross.markers, function ( index, value ) {
                        if ( value.marker.id == indexHover ) {
                            wpet_cross.mapObject.panTo( value.getPosition() );
                            value.open();
                        }
                    } );
                    return false;
                } );

                //hover cross item
                $data_wpet_cross_item.bind( 'mouseenter', function () {
                    $( this ).trigger( 'wpet-active' );
                } );

                $data_wpet_cross_item.bind( 'mouseleave', function () {
                    $( this ).trigger( 'wpet-inactive' );
                } );

                $data_wpet_cross_item.bind( 'wpet-active', function () {

                    let indexHover = $( this ).attr( 'data-wpet-cross-item' );
                    $.each( wpet_cross.markers, function ( index, value ) {
                        if ( value.marker.id == indexHover ) {
                            value.setIcon( wpetMaps.options.markerhover );
                        }
                    } );
                } );

                $data_wpet_cross_item.bind( 'wpet-inactive', function () {
                    let indexHover = $( this ).find( 'a' ).attr( 'href' );
                    $.each( wpet_cross.markers, function ( index, value ) {
                        value.setIcon( value.marker.oldicon );
                    } );

                } );

                if ( wpet_cross.current !== null ) {
                    let latLng = wpet_cross.current.getPosition();
                    wpet_cross.bounds.extend( latLng );
                }

                //check results
                if ( wpet_cross.markers.length > 0 ) {
                    wpet_cross.mapObject.fitBounds( wpet_cross.bounds, {padding: [150, 150]} );
                }

            }
        };

        if ( $( '[data-attr-cross-selling-map]' ).length > 0 ) {

            $( document ).bind( 'wpet_map_ready', function () {

                if ( !$( '[data-attr-cross-selling-map]' ).hasClass( 'wpet-map-detail' ) ) {
                    wpet_cross.init_map();
                    wpet_cross.init_marker();
                } else {
                    $( document ).bind( 'wpet_map_initialized', function () {
                        wpet_cross.map       = wpet_map_object.mapObject.map;
                        wpet_cross.mapObject = wpet_map_object.mapObject;
                        wpet_cross.init_marker();
                    });
                }

                //console.log(window.wpet_map_object.mapAdapter.Map);

            } );

            $( window ).bind( 'wpet_map_results', function () {

                if ( wpet_cross.map !== null ) {

                    let item = window.wpet_map_object.posts[ 0 ];
                    if ( item.wpet_fields.latitude != '' && item.wpet_fields.latitude != undefined && item.wpet_fields.latitude != null && item.wpet_fields.longitude != '' && item.wpet_fields.longitude != undefined && item.wpet_fields.longitude != null ) {

                        // Set latLng object
                        let latLng      = new window.wpet_map_object.mapAdapter.LatLng( item.wpet_fields.latitude, item.wpet_fields.longitude );
                        let marker_data = Theme.themeURL + '/assets/dist/images/markers/marker-hover.svg';
                        let markerOpt   = {
                            title: 'Ici',
                            visible: true,
                            id: item.ID,
                            is_reference: false,
                            oldicon: marker_data,
                            iconSize: [
                                40,
                                40
                            ],
                        };
                        let marker = new window.wpet_map_object.mapAdapter.Marker( latLng, wpet_cross.map, marker_data, markerOpt );
                    }

                }
            } );

            $( 'a[data-toggle="tab"]' ).on( 'shown.bs.tab', function ( e ) {
                let pillId = $( this ).attr( 'aria-controls' );
                $( '.tab-pane' ).hide();
                $( '#' + pillId ).show();

                //call rest route
                wpet_cross.callApi( pillId );
            } );

        }

    }
}
