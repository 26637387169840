import 'slick-carousel/slick/slick.min';
import 'jquery-match-height/dist/jquery.matchHeight-min';

export default class WpetSingle {
    constructor()
    {
        WpetSingle.initializeMobileMap();
        WpetSingle.initializeToggleTitle();
        WpetSingle.initializeSlickTable();
        WpetSingle.initializeSlickGallery();
        WpetSingle.initializeAnchor();
        WpetSingle.initializeFullscreenMode();
    }
    
    static initializeMobileMap()
    {
        let $trigger = $( '[data-trigger="wpet-show-map"]' ),
            $close   = $( '[data-trigger="wpet-close-map"]' ),
            $element = $( '[data-id="wpet-mobile-map"]' );
        
        $trigger.on( 'click', function ( e ) {
            e.preventDefault();
            $element.show();
            window.wpet_map_object.mapObject.resize();
            $( document ).trigger( 'wpet_map_mobile_show' );
        } );
        
        $close.on( 'click', function ( e ) {
            e.preventDefault();
            $element.hide();
        } );
    }
    
    static initializeToggleTitle()
    {
        
        let $trigger = $( '[data-trigger="wpet-open-strate"]' ),
            element  = '[data-id="wpet-strate"]';
        
        $trigger.each( function () {
            $( this ).on( 'click', function () {
                $( this ).parents( element ).toggleClass( 'open' );
                $( this ).toggleClass( 'open' );
            } );
        } );
        
    }
    
    static initializeSlickTable()
    {
        let $slider      = $( '[data-id="wpet-slick-table"]' ),
            $slide       = '[data-id="wpet-slick-table-item"]',
            currentSlide = 0;
        
        if ( $( '.wpetTableContainer--current' ).length > 0 ) {
            currentSlide = Number( $( '.wpetTableContainer--current' ).attr( 'data-table-index' ) );
        }
        
        if ( $slider.length > 0 ) {
            $slider.slick( {
                               slide: $slide,
                               slidesToShow: 1,
                               arrows: true,
                               dots: false,
                               infinite: false,
                               initialSlide: currentSlide,
                           } );
        }
    }
    
    static initializeSlickGallery()
    {
        // Slick gallery
        let $slider = $( '[data-id="wpet-slick-gallery"]' ),
            $slide  = '[data-id="wpet-slick-gallery-item"]';
        
        if ( $slider.length > 0 ) {
            $slider.slick( {
                               slide: $slide,
                               slidesToShow: 1,
                               slidesToScroll: 1,
                               speed: 1000,
                               autoplaySpeed: 5000,
                               arrows: true,
                               dots: false,
                                infinite: false,
                           } );
        }
    }
    
    static initializeAnchor()
    {
        let $anchor      = $( '[data-id="wpet-anchor"]' ),
            $anchor_item = $( '[data-trigger="wpet-anchor-item"]' ),
            $element     = $( '[data-id="wpet-strate"]' );
        
        if ( $anchor.length > 0 ) {
            let shift = 0;
            if ( window.matchMedia( "(max-width: 650px)" ).matches ) {
                shift = 100;
            }
            this.getIdActive( $element, $anchor, $anchor_item, shift );
        }
    }
    
    static getIdActive( partsItem, wrapperNav, navItem, shift )
    {
        shift = (typeof shift !== 'undefined') ? shift : 0;
        
        let itemOffset = {};
        let idActive   = '';
        
        $.each( partsItem, function () {
            itemOffset[ $( this ).attr( 'id' ) ] = $( this ).offset().top;
        } );
    
        $( navItem ).on( 'click', function ( e ) {
            e.preventDefault();
            let target = $( this ).find( 'a' ).attr( 'href' );
            let duration = 1000;
            $( 'html, body' ).animate( {
                                           scrollTop: $( target ).offset().top - shift
                                       }, duration );
        } );
        
        $( window ).scroll( function () {
            
            let navOffset = wrapperNav.offset().top;
            
            for ( let id in itemOffset ) {
                
                if ( itemOffset[ id ] < (navItem.find( 'a[href="#' + id + '"]' ).offset().top + shift) ) {
                    
                    idActive = id;
                }
            }
            
            if ( '' != idActive ) {
                if ( itemOffset[ Object.keys( itemOffset )[ 0 ] ] > (navItem.find( 'a[href="#' + idActive + '"]' ).offset().top + shift) ) {
                    navItem.find( 'a' ).removeClass( 'active' );
                } else {
                    navItem.find( 'a' ).removeClass( 'active' );
                    navItem.find( 'a[href="#' + idActive + '"]' ).addClass( 'active' );
                }
            }
            
        } );
    }
    
    static initializeFullscreenMode()
    {
        let $trigger = $( '[data-trigger="wpet-fullscreen-map"]' ),
            $element = $( '[data-id="wpet-map-lightbox"]' );
        
        if ( $trigger.length > 0 ) {
            
            $trigger.on( 'click', function () {
                $element.toggleClass( 'fullscreen' );
                window.wpet_map_object.mapObject.resize();
            } );
        }
    }
}
