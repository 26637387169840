import Slider from '@cdt81/js/services/slider';
import Utils from '@cdt81/js/services/utils';

export default class WpetItinerary {
    
    static #slick_itinerary;
    
    static #slick_itinerary_settings = {
        slidesToShow: 1,
        arrows: true,
        dots: false,
        infinite: false,
        adaptiveHeight: true,
    };
    
    static #wpet_itinerary = {
        map: null,
        mapObject: null,
        markers: [],
        bounds: null,
        infoWindow: null,
        current: null,
    };
    
    constructor()
    {
        WpetItinerary.initializeMap();
        WpetItinerary.initializeSliderPOI();
        WpetItinerary.initializeItineraryMobile();
    }
    
    static initializeMap()
    {
        if ( $( '.wpet-map-itinerary' ).length > 0 ) {
            
            $( document ).bind( 'wpet_map_initialized', function () {
                WpetItinerary.#wpet_itinerary.map       = wpet_map_object.mapObject.map;
                WpetItinerary.#wpet_itinerary.mapObject = wpet_map_object.mapObject;
                WpetItinerary.callApi();
            } );
            
        }
    }
    
    static callApi()
    {
        $.ajax(
            {
                url: Theme.getItineraryMarkers,
                data: {
                    'id': Theme.postID,
                },
                method: "POST",
            }
        ).done( function ( response ) {
            WpetItinerary.buildMarkers( response );
        } );
    }
    
    static buildMarkers( items )
    {
        WpetItinerary.#wpet_itinerary.bounds = new window.wpet_map_object.mapAdapter.Bounds();
        
        // add markers on map
        for ( let i = 0; i < items.length; i++ ) {
            
            if ( items[ i ].latitude != '' && items[ i ].latitude != undefined && items[ i ].latitude != null
                 && items[ i ].longitude != '' && items[ i ].longitude != undefined && items[ i ].longitude != null
            ) {
                // Set latLng object
                let latLng = new window.wpet_map_object.mapAdapter.LatLng( items[ i ].latitude, items[ i ].longitude );
                
                let marker_data = Theme.themeURL + "/assets/dist/images/markers/markers_number/marker_" + items[ i ].numero + ".svg";
                
                let markerOpt = {
                    title: items[ i ].nom,
                    visible: true,
                    id: items[ i ].ID,
                    is_reference: false,
                    oldicon: wpetMaps.options.marker,
                    iconSize: [
                        30,
                        30
                    ],
                };
                
                let marker = new window.wpet_map_object.mapAdapter.Marker( latLng, WpetItinerary.#wpet_itinerary.map, marker_data, markerOpt );
                
                if ( typeof items[ i ].infobox != 'undefined' && items[ i ].infobox != '' ) {
                    marker.setInfoWindow( items[ i ].infobox, undefined, WpetItinerary.#wpet_itinerary.map );
                }
                
                WpetItinerary.#wpet_itinerary.markers.push( marker );
                WpetItinerary.#wpet_itinerary.bounds.extend( latLng );
            }
        }
    }
    
    static initializeSliderPOI()
    {
        const $element = $( '[data-id="wpet-slider-poi"]' );
        WpetItinerary.#slick_itinerary = $element.slick(WpetItinerary.#slick_itinerary_settings);
    }
    
    static initializeItineraryMobile(){
        const trigger  = '[data-trigger="wpiris-detail-itinerary-mobile-toggle"]',
              $element = $( '[data-id="wpiris-detail-itinerary-mobile"]' );
    
        $( document ).on( 'click', trigger, function ( e ) {
            e.preventDefault();
            $element.toggleClass('open');
            $(trigger).toggleClass('open');
            WpetItinerary.#slick_itinerary.slick( 'unslick' );
            WpetItinerary.initializeSliderPOI();
        } );
    }
    
}
