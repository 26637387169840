import moment from 'moment';
import 'moment/min/locales.min';
import '@cdt81/scss/app-tourism.scss';
import WpetList from '@cdt81/js/wpet/list';
import WpetSingle from '@cdt81/js/wpet/single';
import WpetCrossSelling from '@cdt81/js/wpet/cross_selling';
import WpetInteractiveMap from '@cdt81/js/wpet/interactive_map';
import WpetItinerary from '@cdt81/js/wpet/itinerary';

document.addEventListener( "DOMContentLoaded", function () {
    new WpetList();
    new WpetSingle();
    new WpetCrossSelling();
    new WpetInteractiveMap();
    new WpetItinerary();
} );
