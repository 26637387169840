import 'slick-carousel/slick/slick.min';
import 'jquery-match-height/dist/jquery.matchHeight-min';
import Utils from '@cdt81/js/services/utils';

export default class WpetList {
    
    static #slick_element;
    
    static #wpet_offer_slider_list_settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        infinite: true
    };
    
    constructor()
    {
        WpetList.initializeViewMode();
        WpetList.initializeSlick();
        WpetList.initializeMatchHeight();
        WpetList.initializeFilters();
        WpetList.initializeLinkOffer();
        WpetList.initializeOfferMetaMobile();
        WpetList.initializeTrigger();
        //WpetList.kml();
    }
    
    static initializeViewMode()
    {
        const trigger  = '[data-trigger="wpet-mode"]',
              $element = $( '[data-id="wpet-list"]' );
        
        if ( trigger.length > 0 ) {
            $( document ).on( 'click', trigger, function ( e ) {
                e.preventDefault();
                
                let mode = $( this ).attr( 'data-wpet-switch-mode' );
                $element.attr( 'data-wpet-current-mode', mode );
                window.wpet_map_object.mapObject.resize();
                
                $( document ).trigger( 'wpet_change_mode' );
            } );
        }
    }
    
    static initializeSlick()
    {
        const $trigger     = $( '[data-trigger="wpet-offer-slider"]' );
        WpetList.#slick_element = $trigger.slick( WpetList.#wpet_offer_slider_list_settings );
    }
    
    static initializeMatchHeight()
    {
        const $item = $( '[data-mh]' );
        
        if ( $item.length > 0 ) {
            $item.matchHeight();
        }
    }
    
    static initializeFilters()
    {
        const trigger  = '[data-trigger="wpet-filters-button"]',
              close    = '[data-trigger="wpet-filters-close"]',
              $element = $( '[data-id="wpet-filters"]' );
        
        $( document ).on( 'click', trigger, function ( e ) {
            e.preventDefault();
            $element.css( 'right', '0%' );
            $('body').toggleClass('wpetFiltersOpen');
        } );
        
        $( document ).on( 'click', close, function ( e ) {
            e.preventDefault();
            $element.css( 'right', '-100%' );
            $('body').toggleClass('wpetFiltersOpen');
        } );
    }
    
    static initializeLinkOffer()
    {
        const trigger = '[data-trigger="wpet-click"]';
        
        if ( trigger.length > 0 ) {
            $( document ).on( 'click', trigger, ( e ) => {
                if ( !$( e.target ).hasClass( 'slick-arrow' ) ) {
                    window.location = $( e.currentTarget ).parents( '[data-id="wpet-offer"]' ).find( '[data-id="wpet-offer-link"]' ).attr( 'href' );
                }
            } );
        }
    }
    
    static initializeNumberResults( data )
    {
        const $element = $( '[data-id="wpet-list-total-offer"]' );
        $element.text( data.total_global );
    }
    
    static initializeOfferMetaMobile()
    {
        const trigger  = '[data-trigger="wpet-offer-mobile-button"]',
              element = '[data-id="wpet-offer-mobile-list"]';
    
        $( document ).on( 'click', trigger, function ( e ) {
            e.preventDefault();
            $( this ).parents( '[data-id="wpet-offer"]' ).find( element ).toggle();
        } );
    }
    
    static initializeFormSelect()
    {
        $( 'select' ).parent().not( '.formSelect' ).children( 'select' ).wrap( '<div class="formSelect"></div>' );
    }
    
    static backToTop()
    {
        $( 'html, body' ).animate( {
                                       scrollTop: $( '[data-id="wpet-list"]' ).offset().top
                                   }, 300 );
    }
    
    static initializeTrigger()
    {
        $( document ).on( 'wpet_ajax_build', ( e, data ) => {
            this.initializeFormSelect();
            this.initializeSlick();
            Utils.initializeLazy();
            Utils.tooltip();
            this.initializeMatchHeight();
            this.backToTop();
            this.initializeNumberResults( data );
            if ( window.matchMedia( "'max-width: 1000px" ).matches ) {
                $( '[data-id="wpet-filters"]' ).css( 'right', '-100%' );
            }
        } );
        
        $( document ).on( 'wpet_change_mode', () => {
            WpetList.#slick_element.slick( 'unslick' );
            this.initializeSlick();
            this.initializeMatchHeight();
            this.backToTop();
        } );
    
        $( document ).on( 'wpet_ajax_navigation', () => {
            WpetList.#slick_element.slick( 'unslick' );
            this.initializeSlick();
            this.initializeMatchHeight();
            Utils.initializeLazy();
        } );
    }

    // static kml()
    // {
    //
    //     // let wpet_map_search_on_move = document.getElementById("wpet_map_search_on_move");
    //     //
    //     // if ( wpet_map_search_on_move.checked == true) {
    //     //     var recenter_kml = false;
    //     // } else {
    //     //     var recenter_kml = true;
    //     // }
    //     var recenter_kml = true;
    //     const url      = Theme.themeURL + '/assets/dist/kml/tarn.kml';
    //     const recenter = recenter_kml;
    //     const style    = {
    //         color: '#56759D',
    //         fillOpacity: '0.2'
    //     };
    //     window.wpet_map_object.mapObject.addKmlGpx( url, style, recenter );
    // }
};